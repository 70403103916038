/*

    Theme Name: USA Ultimate U.S. Open
    Theme URI: http://college.usaultimate.org/
    Author: Andrew Lovseth
    Author URI: http://www.andrewlovseth.com/
    Description: Version 1.0 of USA Ultimate U.S. Open
    Version: 1.0

*/
/*

    ----------------------------------------------------------------------
                                01 Global
    ----------------------------------------------------------------------

*/
/* Colors
----------------------------------*/
/* Typography
----------------------------------*/
/* Media Queries
----------------------------------*/
/* HTML5 */
article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, summary {
  display: block; }

audio, canvas, video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

/* HTML & BODY */
html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

html, button, input, select, textarea {
  font-family: sans-serif; }

body {
  margin: 0;
  font-size: 1em;
  line-height: 1.4; }

/* Links */
a {
  text-decoration: none; }

a:focus {
  outline: none; }

a:hover, a:active {
  outline: none; }

/* Typography */
abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: 600; }

i, em {
  font-style: italic; }

blockquote {
  margin: 1em 40px; }

dfn {
  font-style: italic; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

ins {
  background: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background: #ff0;
  color: #000; }

p, pre {
  margin: 1em 0; }

code, kbd, pre, samp {
  font-family: monospace, serif;
  _font-family: 'courier new', monospace;
  font-size: 1em; }

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

q {
  quotes: none; }

q:before, q:after {
  content: '';
  content: none; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  font-weight: normal;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0; }

/* Lists */
dd {
  margin: 0 0 0 40px; }

ul, ol {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0; }

/* Embedded Content */
img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle; }

svg:not(:root) {
  overflow: hidden; }

/* Figure */
figure {
  margin: 0; }

/* Forms */
form {
  margin: 0; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

label {
  cursor: pointer; }

legend {
  border: 0;
  *margin-left: -7px;
  padding: 0;
  white-space: normal; }

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle; }

button, input {
  line-height: normal; }

button, input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
  *overflow: visible; }

button[disabled], input[disabled] {
  cursor: default; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  *width: 13px;
  *height: 13px; }

input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

/* Tables */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td {
  vertical-align: top; }

/* Absolute Positioning */
div, article, section, header, footer, nav, figure, li {
  position: relative; }

/* Selection  */
::-moz-selection {
  background: #FFA409;
  color: #fff;
  text-shadow: none; }

::selection {
  background: #FFA409;
  color: #fff;
  text-shadow: none; }

/* Clearfix */
.clearfix:before, .clearfix:after {
  content: "";
  display: table; }

.clearfix:after {
  clear: both; }

.clearfix {
  *zoom: 1; }

/* Border-Box */
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

/* Image replacement */
.ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;
  *text-indent: -9999px; }

.ir:before {
  content: "";
  display: block;
  width: 0;
  height: 100%; }

/* Responsive Image */
img {
  display: block;
  max-width: 100%; }

html, input, textarea {
  -webkit-font-smoothing: antialiased; }

body {
  text-rendering: optimizeLegibility; }

body, input, textarea {
  color: #2a2a2a;
  font: 400 100%/1 "Roboto Condensed", sans-serif; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: bold; }

a {
  color: #3BAF29;
  transition: color 0.2s ease-in-out; }

.btn {
  background: #FFA409;
  color: #fff;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.5em;
  line-height: 1em;
  display: block;
  padding: 10px 24px 8px;
  text-align: center; }

#main article h2 {
  color: #3BAF29;
  text-transform: uppercase;
  font-size: 2.125em;
  padding: 0 0 15px 0; }

#main article p, #main article ol, #main article ul {
  font-size: 1em;
  line-height: 1.5555em;
  margin: 0 0 30px 0; }

#main aside h3 {
  color: #166936;
  text-transform: uppercase;
  font-size: 1.5em;
  padding: 0 0 20px 0; }

article.default {
  font-family: "Merriweather", serif; }
  article.default p, article.default ul, article.default ol {
    font-size: 1em;
    line-height: 1.5em;
    margin: 0 0 30px 0; }
    @media screen and (min-width: 768px) {
      article.default p, article.default ul, article.default ol {
        font-size: 1.25em;
        margin: 0 0 40px 0; } }
  article.default ul, article.default ol {
    max-width: 560px;
    padding: 0 0 0 30px; }
    @media screen and (min-width: 768px) {
      article.default ul, article.default ol {
        padding: 0 0 0 60px; } }
    article.default ul li, article.default ol li {
      margin: 0 0 10px 0; }
  article.default ul li {
    list-style-type: disc; }
  article.default ol li {
    list-style-type: decimal; }
  article.default h2 {
    font-size: 1.75em;
    color: #3BAF29;
    padding: 10px 0 10px 0; }
    @media screen and (min-width: 768px) {
      article.default h2 {
        font-size: 2em; } }
  article.default * + h2 {
    padding-top: 50px; }
  article.default h3 {
    font-size: 1.25em;
    color: #3BAF29;
    padding: 10px 0 10px 0; }
    @media screen and (min-width: 768px) {
      article.default h3 {
        font-size: 1.5em; } }
  article.default h4 {
    font-size: 1em;
    color: #3BAF29;
    text-transform: uppercase;
    padding: 10px 0 10px 0; }
    @media screen and (min-width: 768px) {
      article.default h4 {
        font-size: 1.25em; } }
  article.default a {
    text-decoration: underline; }
  article.default blockquote {
    padding: 5px 0 5px 30px;
    border-left: 5px solid #FCFCFC;
    max-width: 540px;
    margin: 0 0 30px 0; }
    @media screen and (min-width: 768px) {
      article.default blockquote {
        font-size: 1.25em;
        margin: 0 0 40px 0; } }
    @media screen and (min-width: 768px) {
      article.default blockquote {
        margin: 0 0 40px 60px; } }
    article.default blockquote p {
      font-size: 0.8125em;
      line-height: 2em; }
      article.default blockquote p:last-of-type {
        margin: 0; }
  article.default img {
    margin: 0 0 30px 0; }

body {
  background: #fff; }

.wrapper {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 25px;
  transition: padding 0.2s ease-in; }
  @media screen and (min-width: 768px) {
    .wrapper {
      padding: 0 50px; } }
  @media screen and (min-width: 1260px) {
    .wrapper {
      padding: 0; } }

body.error404 #main article {
  min-height: 600px; }

.cover {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }

.hero-link {
  display: block; }

.hero-image {
  height: 240px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }
  @media screen and (min-width: 640px) {
    .hero-image {
      height: 360px; } }
  @media screen and (min-width: 1024px) {
    .hero-image {
      height: 480px; } }
  @media screen and (min-width: 1280px) {
    .hero-image {
      height: 600px; } }
  .hero-image .wrapper {
    display: flex;
    align-items: flex-end;
    height: 100%; }
  .hero-image .info {
    padding: 0 0 25px 0; }
    @media screen and (min-width: 640px) {
      .hero-image .info {
        padding: 0 0 50px 0; } }
  .hero-image h1 {
    color: #fff;
    text-transform: uppercase;
    font-size: 1.25em;
    max-width: 1000px;
    font-family: "Montserrat", sans-serif; }
    @media screen and (min-width: 640px) {
      .hero-image h1 {
        font-size: 2.25em; } }
    @media screen and (min-width: 1024px) {
      .hero-image h1 {
        font-size: 3em; } }
    .hero-image h1 span {
      background: rgba(150, 201, 61, 0.8);
      padding: 10px;
      display: inline-block; }
      @media screen and (min-width: 640px) {
        .hero-image h1 span {
          padding: 15px 20px; } }
  .hero-image h2 {
    color: #fff;
    text-transform: uppercase;
    font-size: 0.875em;
    font-family: "Roboto Condensed", sans-serif; }
    @media screen and (min-width: 768px) {
      .hero-image h2 {
        font-size: 1.25em; } }
    @media screen and (min-width: 1280px) {
      .hero-image h2 {
        font-size: 1.5em; } }
    .hero-image h2 span {
      background: rgba(252, 206, 1, 0.8);
      padding: 10px;
      display: inline-block; }
      @media screen and (min-width: 640px) {
        .hero-image h2 span {
          padding: 15px 20px; } }

section#main {
  padding: 30px 0; }
  @media screen and (min-width: 768px) {
    section#main {
      padding: 50px 0; } }
  section#main .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start; }
  section#main article {
    width: 100%;
    padding: 0 0 50px 0; }
    @media screen and (min-width: 1024px) {
      section#main article {
        width: 55%;
        padding: 0; } }
  section#main .aside-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    @media screen and (min-width: 1024px) {
      section#main .aside-wrapper {
        width: 42.5%; } }

aside#latest-news article {
  border: 1px solid rgba(0, 42, 92, 0.2);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px !important;
  width: 100% !important;
  margin: 0 0 20px 0; }
  aside#latest-news article h5 {
    color: #96C93D;
    font-size: 1em;
    padding: 0 0 5px 0; }
  aside#latest-news article h4 a {
    color: #3BAF29;
    font-size: 1.375em; }
    aside#latest-news article h4 a:hover {
      text-decoration: underline; }

aside#latest-news .btn {
  margin: 10px auto 0 auto;
  max-width: 200px; }

.game-block {
  padding: 0 0 50px 0; }
  .game-block h4 {
    text-transform: uppercase;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 0.875em;
    padding: 0 0 10px 0; }
  .game-block .fixture:last-of-type {
    border-bottom: 1px solid #d5d5d5; }

.fixture, .result {
  background: #fafafa;
  border-top: 1px solid #d5d5d5;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center; }
  .fixture:nth-child(odd), .result:nth-child(odd) {
    background: #fff; }
  .fixture .time, .result .time {
    font-size: 0.75em;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    width: 100%;
    padding: 0 0 15px 0;
    font-weight: bold;
    color: #8a8a8a; }
  .fixture .match-up, .result .match-up {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center; }
    .fixture .match-up .versus, .result .match-up .versus {
      text-align: center;
      width: 30%;
      font-weight: bold;
      color: #2a2a2a; }
    .fixture .match-up .team-a,
    .fixture .match-up .team-b, .result .match-up .team-a,
    .result .match-up .team-b {
      font-size: 1em;
      font-weight: bold;
      width: 30%;
      max-width: 200px; }
      .fixture .match-up .team-a a,
      .fixture .match-up .team-b a, .result .match-up .team-a a,
      .result .match-up .team-b a {
        color: #2a2a2a; }
    .fixture .match-up .team-a, .result .match-up .team-a {
      text-align: right; }
    .fixture .match-up .team-b, .result .match-up .team-b {
      text-align: left; }
  .fixture .link, .result .link {
    font-size: 0.75em;
    text-align: center;
    width: 100%;
    padding: 15px 0 0 0;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    font-weight: bold;
    color: #8a8a8a; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
/* Icons */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 60px;
  width: 60px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  z-index: 100;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.4; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.85;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: 20px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: 20px; }
  @media screen and (min-width: 1280px) {
    .slick-prev {
      left: 40px; }
      [dir="rtl"] .slick-prev {
        left: auto;
        right: 40px; } }
  .slick-prev:before {
    background: url(images/left-arrow.png) no-repeat 0 0;
    width: 60px;
    height: 60px;
    content: '';
    display: block; }

.slick-next {
  right: 20px; }
  [dir="rtl"] .slick-next {
    left: 20px;
    right: auto; }
  @media screen and (min-width: 1280px) {
    .slick-next {
      right: 40px; }
      [dir="rtl"] .slick-next {
        left: 40px;
        right: auto; } }
  .slick-next:before {
    background: url(images/right-arrow.png) no-repeat 0 0;
    width: 60px;
    height: 60px;
    content: '';
    display: block; }

/* Dots */
.slick-dots {
  position: absolute;
  bottom: 5px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  @media screen and (min-width: 640px) {
    .slick-dots {
      bottom: 15px; } }
  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      width: 12px;
      height: 12px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: ' ';
        width: 12px;
        height: 12px;
        border-radius: 50%;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        background-color: #fff;
        opacity: 0.4;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: #fff;
      opacity: 0.85; }

/*

    ----------------------------------------------------------------------
                                02 Sections
    ----------------------------------------------------------------------

*/
header {
  padding: 30px 0; }
  header .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  header .logo {
    display: flex;
    align-items: center; }
    header .logo img {
      margin-right: 20px; }
    header .logo h1 {
      display: none;
      text-transform: uppercase;
      font-size: 2.25em;
      letter-spacing: 0.025em; }
      @media screen and (min-width: 640px) {
        header .logo h1 {
          display: block; } }
      @media screen and (min-width: 1024px) {
        header .logo h1 {
          font-size: 3.5em; } }
      header .logo h1 a {
        color: #FFA409; }
  header #toggle {
    width: 34px;
    cursor: pointer;
    padding: 8px 5px 8px 5px;
    transition: all 0.6s cubic-bezier(0.7, 0.01, 0.3, 1);
    margin-right: 10px; }
    header #toggle .patty {
      width: 40px;
      height: 5px;
      background: #3BAF29;
      transition: all 0.3s; }
      header #toggle .patty:after, header #toggle .patty:before {
        content: '';
        display: block;
        width: 40px;
        height: 5px;
        background: #3BAF29;
        position: absolute;
        z-index: -1;
        transition: all 0.3s; }
      header #toggle .patty:before {
        top: -10px; }
      header #toggle .patty:after {
        top: 10px; }
  header.open #toggle .patty {
    background: none; }
    header.open #toggle .patty:after {
      top: 0;
      transform: rotate(45deg); }
    header.open #toggle .patty:before {
      top: 0;
      transform: rotate(-45deg); }
  header a.watch-live {
    display: none;
    margin: 0 40px 0 auto;
    padding: 8px 12px 6px 12px;
    color: #FFA409;
    border: 1px solid #FFA409;
    font-weight: 700;
    font-size: 0.875em;
    text-transform: uppercase;
    letter-spacing: 0.05em; }
    header a.watch-live:hover {
      background: #FFA409;
      color: #fff; }
    @media screen and (min-width: 1024px) {
      header a.watch-live {
        display: inline-block; } }

nav#dropdown {
  display: none;
  background: #FCFCFC;
  padding: 25px 0 10px 0; }
  @media screen and (min-width: 1024px) {
    nav#dropdown {
      padding: 50px 0; } }
  nav#dropdown .wrapper {
    display: flex;
    flex-wrap: wrap; }
    nav#dropdown .wrapper .col {
      flex-basis: 100%;
      padding: 0 0 15px 0; }
      @media screen and (min-width: 1024px) {
        nav#dropdown .wrapper .col {
          flex-basis: 33.3333%;
          text-align: left;
          padding: 0; } }
  @media screen and (min-width: 1024px) {
    nav#dropdown .col.news {
      display: none; } }
  nav#dropdown h4 {
    text-transform: uppercase;
    font-size: 1.25em;
    line-height: 1em;
    padding: 0 0 10px 0; }
    @media screen and (min-width: 768px) {
      nav#dropdown h4 {
        font-size: 2.5em;
        padding: 0 0 20px 0; } }
    nav#dropdown h4 a {
      color: #FFA409; }
  nav#dropdown p {
    display: none;
    font-size: 0.875em;
    line-height: 1.375em;
    padding: 0 0 20px 0; }
    @media screen and (min-width: 1024px) {
      nav#dropdown p {
        display: block; } }
  nav#dropdown ul {
    display: none; }
    @media screen and (min-width: 1024px) {
      nav#dropdown ul {
        display: block; } }
    nav#dropdown ul li {
      padding: 0 0 15px 0;
      display: flex;
      align-items: center; }
      nav#dropdown ul li.divider {
        text-indent: -9999px; }
      nav#dropdown ul li span.sep {
        display: inline-block;
        padding: 0 5px;
        color: #96C93D; }
      nav#dropdown ul li a {
        font-weight: bold; }
        nav#dropdown ul li a:hover {
          text-decoration: underline; }
        nav#dropdown ul li a.division {
          color: #3BAF29;
          font-family: "Montserrat", sans-serif;
          font-size: 1.25em; }
        nav#dropdown ul li a.sub-link {
          color: #96C93D;
          font-size: 0.875em; }

nav#bar {
  background: #3BAF29;
  color: #fff;
  padding: 20px 0; }
  nav#bar .wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }
    @media screen and (min-width: 1024px) {
      nav#bar .wrapper {
        justify-content: flex-start; } }
  nav#bar h1 {
    text-transform: uppercase;
    font-size: 1.875em; }
    nav#bar h1 a {
      color: #fff; }
  nav#bar #menu-toggle {
    width: 32px;
    height: 18px;
    background: url(images/menu-toggle.png) no-repeat 0 0;
    background-size: cover;
    display: block;
    text-indent: -99999px;
    margin: 0 3px 0 0; }
    @media screen and (min-width: 1024px) {
      nav#bar #menu-toggle {
        display: none; } }
  nav#bar .menu {
    display: none;
    width: 100%;
    padding: 10px 0 0 0; }
    @media screen and (min-width: 1024px) {
      nav#bar .menu {
        display: flex !important;
        width: auto;
        padding: 0; } }
    nav#bar .menu a {
      color: #fff;
      display: block;
      font-size: 1.125em;
      font-weight: bold;
      margin: 10px 0; }
      @media screen and (min-width: 1024px) {
        nav#bar .menu a {
          margin: 0 0 0 20px; } }
      @media screen and (min-width: 1280px) {
        nav#bar .menu a {
          margin: 0 0 0 40px; } }
      nav#bar .menu a:hover {
        text-decoration: underline; }

#sub-bar {
  background: #96C93D;
  padding: 15px 0;
  display: none; }
  @media screen and (min-width: 1024px) {
    #sub-bar {
      display: block; } }
  @media screen and (min-width: 768px) {
    #sub-bar .wrapper {
      display: flex !important; } }
  #sub-bar a {
    color: #fff;
    display: block;
    font-size: 1em;
    margin: 10px 0;
    font-weight: bold; }
    @media screen and (min-width: 768px) {
      #sub-bar a {
        font-size: 0.875em;
        margin: 0 40px 0 0; } }
    #sub-bar a:hover {
      text-decoration: underline; }

#utility-nav {
  background: #FCFCFC;
  padding: 12px 0 10px 0;
  border-bottom: 1px solid rgba(150, 201, 61, 0.2);
  display: none; }
  @media screen and (min-width: 768px) {
    #utility-nav {
      display: block; } }
  #utility-nav .wrapper {
    display: flex;
    justify-content: flex-end; }
  #utility-nav div.division {
    display: flex;
    margin: 0 0 0 30px;
    color: #166936;
    align-items: center; }
    #utility-nav div.division a, #utility-nav div.division span {
      font-size: 0.8215em; }
    #utility-nav div.division span.dot {
      display: inline-block;
      padding: 0 5px; }
    #utility-nav div.division strong {
      padding: 0 7px 0 0; }
      #utility-nav div.division strong a {
        font-weight: bold;
        font-size: 0.875em; }
  #utility-nav a {
    font-weight: bold;
    display: inline;
    color: #166936;
    font-weight: 400; }
    #utility-nav a.news {
      font-weight: bold;
      font-size: 0.875em; }
    #utility-nav a:hover {
      text-decoration: underline; }

footer {
  background: #2a2a2a;
  color: #fff;
  padding: 100px 0 50px 0; }
  footer .logo a {
    display: block;
    max-width: 100px; }
  footer .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    footer .wrapper .col {
      flex-basis: 100%;
      padding: 0 0 25px 0; }
      @media screen and (min-width: 1024px) {
        footer .wrapper .col {
          flex-basis: 25%;
          text-align: left;
          padding: 0; } }
  footer h4 {
    text-transform: uppercase;
    font-size: 1.5em;
    line-height: 1em;
    padding: 0 0 20px 0; }
    footer h4 a {
      color: #fff; }
      footer h4 a:hover {
        color: #FFA409; }
  footer p {
    font-size: 0.8125em;
    line-height: 1.375em;
    padding: 0 0 20px 0;
    font-weight: bold; }
  footer ul {
    display: none; }
    @media screen and (min-width: 1024px) {
      footer ul {
        display: block; } }
    footer ul li {
      padding: 0 0 10px 0;
      display: flex;
      align-items: center; }
      footer ul li.divider {
        text-indent: -9999px; }
      footer ul li .sep {
        padding: 0 5px; }
      footer ul li a {
        font-size: 0.8125em;
        color: #fff; }
        footer ul li a:hover {
          color: #96C93D; }
        footer ul li a.division {
          font-size: 1em;
          font-weight: bold; }
  footer .copyright {
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 100px 0 0 0;
    border-top: 1px solid #2a2a2a;
    padding: 20px 0 0 0; }
    footer .copyright p {
      font-weight: normal;
      font-size: 0.75em;
      color: #666;
      padding: 0; }
      footer .copyright p.credits {
        text-align: right; }
        footer .copyright p.credits a {
          color: #666;
          border-bottom: 1px dotted; }

#sponsors, #media-partners {
  background: #FCFCFC;
  padding: 50px 0 0 0; }
  #sponsors h2, #media-partners h2 {
    color: #3BAF29;
    text-transform: uppercase;
    text-align: center;
    padding: 0 0 50px 0; }
  #sponsors #sponsors-wrapper, #media-partners #sponsors-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto; }
    #sponsors #sponsors-wrapper a, #media-partners #sponsors-wrapper a {
      width: 100%;
      display: block;
      margin: 0 auto 50px auto; }
      @media screen and (min-width: 1024px) {
        #sponsors #sponsors-wrapper a, #media-partners #sponsors-wrapper a {
          width: 33.3333%; }
          #sponsors #sponsors-wrapper a.five-ultimate img, #media-partners #sponsors-wrapper a.five-ultimate img {
            max-width: 400px; } }
      #sponsors #sponsors-wrapper a img, #media-partners #sponsors-wrapper a img {
        margin: 0 auto;
        max-width: 200px;
        max-height: 100px; }

#media-partners {
  background: #3a3a3a; }
  #media-partners h2 {
    color: #fff; }

body.page-template-default.page section#generic-page {
  padding: 50px 0; }
  @media screen and (min-width: 768px) {
    body.page-template-default.page section#generic-page {
      padding: 50px 0 100px 0; } }
  body.page-template-default.page section#generic-page article .article-header {
    padding: 0 0 20px 0; }
    body.page-template-default.page section#generic-page article .article-header h1 {
      font-size: 3em;
      color: #2a2a2a;
      text-transform: uppercase; }
  body.page-template-default.page section#generic-page article .article-body > *:not(img) {
    max-width: 800px; }

/*

    ----------------------------------------------------------------------
                                03 Templates
    ----------------------------------------------------------------------

*/
body#home section#main .wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start; }

body#home section#main section#tiles {
  width: 100%; }
  @media screen and (min-width: 480px) {
    body#home section#main section#tiles {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      body#home section#main section#tiles .col {
        width: 47.5%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; } }
    @media screen and (min-width: 480px) and (min-width: 640px) {
      body#home section#main section#tiles .col {
        width: 48%; } }
    @media screen and (min-width: 480px) and (min-width: 768px) {
      body#home section#main section#tiles .col {
        width: 100%; } }
    @media screen and (min-width: 480px) and (min-width: 1024px) {
      body#home section#main section#tiles .col {
        width: 48.5%; } }
    @media screen and (min-width: 480px) and (min-width: 1280px) {
      body#home section#main section#tiles .col {
        width: 49%; } }
  @media screen and (min-width: 768px) {
    body#home section#main section#tiles {
      width: 65%; } }
  @media screen and (min-width: 1280px) {
    body#home section#main section#tiles {
      width: 78.5%; } }
  body#home section#main section#tiles .tile {
    display: block;
    width: 100%;
    margin: 0 0 20px 0;
    position: relative; }
    body#home section#main section#tiles .tile:after {
      content: "";
      display: block;
      padding-bottom: 50%; }
      @media screen and (min-width: 480px) {
        body#home section#main section#tiles .tile:after {
          padding-bottom: 100%; } }
    @media screen and (min-width: 768px) {
      body#home section#main section#tiles .tile {
        width: 47.5%; }
        body#home section#main section#tiles .tile-1, body#home section#main section#tiles .tile-6 {
          width: 100%; } }
    @media screen and (min-width: 1024px) {
      body#home section#main section#tiles .tile {
        width: 47%; }
        body#home section#main section#tiles .tile-1, body#home section#main section#tiles .tile-6 {
          width: 100%; }
        body#home section#main section#tiles .tile-2, body#home section#main section#tiles .tile-3, body#home section#main section#tiles .tile-6 {
          margin-bottom: 0; } }
    @media screen and (min-width: 1280px) {
      body#home section#main section#tiles .tile {
        width: 47.5%; }
        body#home section#main section#tiles .tile-1, body#home section#main section#tiles .tile-6 {
          width: 100%; } }
    body#home section#main section#tiles .tile .label {
      left: 5%;
      bottom: 5%;
      position: absolute;
      max-width: 90%;
      font-family: "Montserrat", sans-serif;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1.5em;
      padding: 10px;
      color: #fff;
      background: rgba(255, 164, 9, 0.8);
      display: inline-block; }
      @media screen and (min-width: 640px) {
        body#home section#main section#tiles .tile .label {
          font-size: 1em; } }
      @media screen and (min-width: 768px) {
        body#home section#main section#tiles .tile .label {
          font-size: 1.5em; } }
      @media screen and (min-width: 1024px) {
        body#home section#main section#tiles .tile .label {
          font-size: 1em; } }
      @media screen and (min-width: 1280px) {
        body#home section#main section#tiles .tile .label {
          font-size: 1.25em; } }
    @media screen and (min-width: 1024px) {
      body#home section#main section#tiles .tile-1 .label, body#home section#main section#tiles .tile-6 .label {
        font-size: 1.5em; } }
    @media screen and (min-width: 1280px) {
      body#home section#main section#tiles .tile-1 .label, body#home section#main section#tiles .tile-6 .label {
        font-size: 2.5em; } }

body#home section#main aside#latest-news {
  width: 100%;
  padding: 50px 0; }
  @media screen and (min-width: 768px) {
    body#home section#main aside#latest-news {
      width: 30%;
      padding: 0; } }
  @media screen and (min-width: 1280px) {
    body#home section#main aside#latest-news {
      width: 18.5%; } }

body.page-template-overview #main .wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start; }

body.page-template-overview #main article {
  width: 100%;
  padding: 0 0 50px 0; }
  @media screen and (min-width: 1024px) {
    body.page-template-overview #main article {
      width: 55%;
      padding: 0; } }
  body.page-template-overview #main article .about {
    padding: 0 0 50px 0; }
    body.page-template-overview #main article .about p {
      font-family: "Merriweather", serif;
      font-size: 0.9375em;
      line-height: 1.75em; }
  body.page-template-overview #main article .faqs .faq {
    margin: 0 0 20px 0; }
    body.page-template-overview #main article .faqs .faq .question {
      color: #2a2a2a;
      font-weight: bold;
      font-family: "Montserrat", sans-serif;
      font-size: 1.125em;
      display: block;
      padding: 20px;
      background: #FCFCFC;
      transition: none; }
      body.page-template-overview #main article .faqs .faq .question:hover, body.page-template-overview #main article .faqs .faq .question.active {
        color: #fff;
        background: #3BAF29; }
    body.page-template-overview #main article .faqs .faq .answer {
      display: none;
      padding: 20px 20px 0 20px; }
      body.page-template-overview #main article .faqs .faq .answer p {
        font-size: 0.9375em;
        line-height: 1.75em;
        font-family: "Merriweather", serif; }
      body.page-template-overview #main article .faqs .faq .answer p:last-of-type {
        margin: 0; }

body.page-template-overview #main .aside-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  @media screen and (min-width: 1024px) {
    body.page-template-overview #main .aside-wrapper {
      width: 42.5%; } }
  body.page-template-overview #main .aside-wrapper aside#latest-news {
    width: 100%; }

body.template-division section#main .wrapper {
  display: block; }

body.template-division section#main h3.section-heading {
  color: #FFA409;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 1.5em;
  letter-spacing: 0.05em;
  text-align: center;
  padding: 0 0 50px 0; }

body.template-division section#schedule-results #tab-links {
  border-bottom: 1px solid #96C93D;
  display: flex;
  justify-content: center;
  margin: 0 0 40px 0; }
  body.template-division section#schedule-results #tab-links a {
    display: inline-block;
    padding: 12px 24px;
    background: #fff;
    border: 1px solid #96C93D;
    border-bottom: none;
    color: #96C93D;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    font-size: 1.125em;
    font-weight: bold; }
    body.template-division section#schedule-results #tab-links a.active {
      background: #3BAF29;
      color: #fff;
      border: 1px solid #3BAF29;
      border-bottom: none; }

body.template-division section#schedule-results #tabs {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap; }

body.template-division section#schedule-results article {
  width: 100%;
  display: none; }
  body.template-division section#schedule-results article h4 {
    padding: 0 0 20px 0;
    color: #3BAF29;
    font-size: 1.5em;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    width: 100%; }
  body.template-division section#schedule-results article .score-reporter {
    display: block;
    text-align: center;
    margin: -20px 0 40px 0;
    font-weight: bold;
    font-size: 0.875em;
    text-decoration: underline; }
  body.template-division section#schedule-results article .pool {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 100px 0; }
    body.template-division section#schedule-results article .pool h5 {
      text-transform: uppercase;
      letter-spacing: 0.03em;
      font-family: "Roboto Condensed", sans-serif;
      padding: 0 0 10px 0; }
    body.template-division section#schedule-results article .pool .standings {
      width: 100%; }
      @media screen and (min-width: 768px) {
        body.template-division section#schedule-results article .pool .standings {
          width: 48.5%; } }
      body.template-division section#schedule-results article .pool .standings .header,
      body.template-division section#schedule-results article .pool .standings .row {
        display: flex;
        align-items: center; }
        body.template-division section#schedule-results article .pool .standings .header .team,
        body.template-division section#schedule-results article .pool .standings .row .team {
          width: 60%; }
        body.template-division section#schedule-results article .pool .standings .header .win-loss,
        body.template-division section#schedule-results article .pool .standings .header .tie,
        body.template-division section#schedule-results article .pool .standings .row .win-loss,
        body.template-division section#schedule-results article .pool .standings .row .tie {
          width: 20%; }
      body.template-division section#schedule-results article .pool .standings .header {
        background: #2a2a2a;
        color: #fff;
        padding: 10px; }
        body.template-division section#schedule-results article .pool .standings .header span {
          font-weight: bold; }
      body.template-division section#schedule-results article .pool .standings .row {
        padding: 20px 10px;
        border-bottom: 1px solid #FCFCFC; }
        body.template-division section#schedule-results article .pool .standings .row:nth-child(odd) {
          background: #fafafa; }
        body.template-division section#schedule-results article .pool .standings .row .team {
          font-weight: bold;
          font-size: 1em; }
          body.template-division section#schedule-results article .pool .standings .row .team a {
            color: #2a2a2a; }
        body.template-division section#schedule-results article .pool .standings .row .win-loss {
          font-size: 1em; }
        body.template-division section#schedule-results article .pool .standings .row .tie {
          font-size: 0.875em;
          font-style: italic; }
    body.template-division section#schedule-results article .pool .games {
      width: 100%;
      padding: 50px 0 0 0; }
      @media screen and (min-width: 768px) {
        body.template-division section#schedule-results article .pool .games {
          width: 48.5%;
          padding: 0; } }
  body.template-division section#schedule-results article#bracket h5 {
    text-align: center;
    text-transform: uppercase;
    font-family: "Roboto Condensed", sans-serif; }
  body.template-division section#schedule-results article#bracket section#tournament {
    display: flex;
    flex-direction: row;
    justify-content: center; }
  body.template-division section#schedule-results article#bracket .round {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 200px;
    list-style: none;
    padding: 0; }
  body.template-division section#schedule-results article#bracket .round .spacer {
    flex-grow: 1;
    min-height: 40px; }
  body.template-division section#schedule-results article#bracket .round .spacer.first,
  body.template-division section#schedule-results article#bracket .round .spacer:last-of-type {
    flex-grow: 0.5; }
  body.template-division section#schedule-results article#bracket .round .game-spacer {
    flex-grow: 1; }
  body.template-division section#schedule-results article#bracket li.game {
    font-size: 0.8125em; }
    body.template-division section#schedule-results article#bracket li.game a {
      color: #2a2a2a; }
      body.template-division section#schedule-results article#bracket li.game a:hover {
        color: #3BAF29; }
    body.template-division section#schedule-results article#bracket li.game span {
      float: right;
      margin-right: 10px; }
    body.template-division section#schedule-results article#bracket li.game-top {
      border-bottom: 1px solid #aaa;
      padding: 0 0 5px 10px;
      font-weight: bold;
      min-height: 18px; }
    body.template-division section#schedule-results article#bracket li.game-spacer {
      border-right: 1px solid #aaa;
      min-height: 80px;
      display: flex;
      align-items: center;
      font-size: 0.75em;
      padding: 10px; }
    body.template-division section#schedule-results article#bracket li.game-bottom {
      border-top: 1px solid #aaa;
      padding: 5px 0 0 10px;
      font-weight: bold; }
    body.template-division section#schedule-results article#bracket li.game-top.empty, body.template-division section#schedule-results article#bracket li.game-spacer.empty, body.template-division section#schedule-results article#bracket li.game-bottom.empty {
      border-color: transparent;
      opacity: 0; }
    body.template-division section#schedule-results article#bracket li.game-top.empty, body.template-division section#schedule-results article#bracket li.game-bottom.empty {
      min-height: 13px; }

body.template-division section#teams {
  padding: 100px 0 0 0; }
  body.template-division section#teams #teams-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%; }
    body.template-division section#teams #teams-wrapper .team {
      width: 48%;
      margin: 0 0 50px 0; }
      @media screen and (min-width: 640px) {
        body.template-division section#teams #teams-wrapper .team {
          width: 31.3333%; } }
      @media screen and (min-width: 1024px) {
        body.template-division section#teams #teams-wrapper .team {
          width: 23%;
          margin: 0 0 100px 0; } }
      body.template-division section#teams #teams-wrapper .team .logo img {
        margin: 0 auto;
        max-width: 96px; }
      body.template-division section#teams #teams-wrapper .team .info {
        text-align: center;
        padding: 10px 0 0 0; }
        body.template-division section#teams #teams-wrapper .team .info h3 {
          font-family: "Roboto Condensed", sans-serif;
          font-size: 1em;
          letter-spacing: 0.05em;
          text-transform: uppercase; }
          body.template-division section#teams #teams-wrapper .team .info h3 a {
            color: #2a2a2a; }

body.template-schedule-results #main .wrapper {
  display: block; }

body.template-schedule-results #main #tab-links {
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: center;
  margin: 0 0 40px 0; }
  body.template-schedule-results #main #tab-links a {
    display: inline-block;
    padding: 12px 24px;
    background: #fff;
    border: 1px solid #eee;
    color: #2a2a2a;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    font-size: 1.125em;
    font-weight: bold; }
    body.template-schedule-results #main #tab-links a.active {
      background: #2a2a2a;
      color: #fff; }

body.template-schedule-results #main #tabs {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap; }

body.template-schedule-results #main article {
  width: 100%;
  display: none; }
  body.template-schedule-results #main article h4 {
    padding: 0 0 20px 0;
    color: #3BAF29;
    font-size: 1.5em;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    width: 100%; }
  body.template-schedule-results #main article .score-reporter {
    display: block;
    text-align: center;
    margin: -20px 0 40px 0;
    font-weight: bold;
    font-size: 0.875em;
    text-decoration: underline; }
  body.template-schedule-results #main article .pool {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 100px 0; }
    body.template-schedule-results #main article .pool h5 {
      text-transform: uppercase;
      letter-spacing: 0.03em;
      font-family: "Roboto Condensed", sans-serif;
      padding: 0 0 10px 0; }
    body.template-schedule-results #main article .pool .standings {
      width: 100%; }
      @media screen and (min-width: 768px) {
        body.template-schedule-results #main article .pool .standings {
          width: 48.5%; } }
      body.template-schedule-results #main article .pool .standings .header,
      body.template-schedule-results #main article .pool .standings .row {
        display: flex;
        align-items: center; }
        body.template-schedule-results #main article .pool .standings .header .team,
        body.template-schedule-results #main article .pool .standings .row .team {
          width: 60%; }
        body.template-schedule-results #main article .pool .standings .header .win-loss,
        body.template-schedule-results #main article .pool .standings .header .tie,
        body.template-schedule-results #main article .pool .standings .row .win-loss,
        body.template-schedule-results #main article .pool .standings .row .tie {
          width: 20%; }
      body.template-schedule-results #main article .pool .standings .header {
        background: #2a2a2a;
        color: #fff;
        padding: 10px; }
        body.template-schedule-results #main article .pool .standings .header span {
          font-weight: bold; }
      body.template-schedule-results #main article .pool .standings .row {
        padding: 20px 10px;
        border-bottom: 1px solid #FCFCFC; }
        body.template-schedule-results #main article .pool .standings .row:nth-child(odd) {
          background: #fafafa; }
        body.template-schedule-results #main article .pool .standings .row .team {
          font-weight: bold;
          font-size: 1em; }
          body.template-schedule-results #main article .pool .standings .row .team a {
            color: #2a2a2a; }
        body.template-schedule-results #main article .pool .standings .row .win-loss {
          font-size: 1em; }
        body.template-schedule-results #main article .pool .standings .row .tie {
          font-size: 0.875em;
          font-style: italic; }
    body.template-schedule-results #main article .pool .games {
      width: 100%;
      padding: 50px 0 0 0; }
      @media screen and (min-width: 768px) {
        body.template-schedule-results #main article .pool .games {
          width: 48.5%;
          padding: 0; } }
  body.template-schedule-results #main article#bracket h5 {
    text-align: center;
    text-transform: uppercase;
    font-family: "Roboto Condensed", sans-serif; }
  body.template-schedule-results #main article#bracket section#tournament {
    display: flex;
    flex-direction: row;
    justify-content: center; }
  body.template-schedule-results #main article#bracket .round {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 200px;
    list-style: none;
    padding: 0; }
  body.template-schedule-results #main article#bracket .round .spacer {
    flex-grow: 1;
    min-height: 40px; }
  body.template-schedule-results #main article#bracket .round .spacer.first,
  body.template-schedule-results #main article#bracket .round .spacer:last-of-type {
    flex-grow: 0.5; }
  body.template-schedule-results #main article#bracket .round .game-spacer {
    flex-grow: 1; }
  body.template-schedule-results #main article#bracket li.game {
    font-size: 0.8125em; }
    body.template-schedule-results #main article#bracket li.game a {
      color: #2a2a2a; }
      body.template-schedule-results #main article#bracket li.game a:hover {
        color: #3BAF29; }
    body.template-schedule-results #main article#bracket li.game span {
      float: right;
      margin-right: 10px; }
    body.template-schedule-results #main article#bracket li.game-top {
      border-bottom: 1px solid #aaa;
      padding: 0 0 5px 10px;
      font-weight: bold;
      min-height: 18px; }
    body.template-schedule-results #main article#bracket li.game-spacer {
      border-right: 1px solid #aaa;
      min-height: 80px;
      display: flex;
      align-items: center;
      font-size: 0.75em;
      padding: 10px; }
    body.template-schedule-results #main article#bracket li.game-bottom {
      border-top: 1px solid #aaa;
      padding: 5px 0 0 10px;
      font-weight: bold; }
    body.template-schedule-results #main article#bracket li.game-top.empty, body.template-schedule-results #main article#bracket li.game-spacer.empty, body.template-schedule-results #main article#bracket li.game-bottom.empty {
      border-color: transparent;
      opacity: 0; }
    body.template-schedule-results #main article#bracket li.game-top.empty, body.template-schedule-results #main article#bracket li.game-bottom.empty {
      min-height: 13px; }

body.page-template-teams #teams-wrapper,
body.page-template-schedule-results #teams-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%; }
  body.page-template-teams #teams-wrapper .team,
  body.page-template-schedule-results #teams-wrapper .team {
    width: 48%;
    margin: 0 0 50px 0; }
    @media screen and (min-width: 640px) {
      body.page-template-teams #teams-wrapper .team,
      body.page-template-schedule-results #teams-wrapper .team {
        width: 31.3333%; } }
    @media screen and (min-width: 1024px) {
      body.page-template-teams #teams-wrapper .team,
      body.page-template-schedule-results #teams-wrapper .team {
        width: 23%;
        margin: 0 0 100px 0; } }
    body.page-template-teams #teams-wrapper .team .logo img,
    body.page-template-schedule-results #teams-wrapper .team .logo img {
      margin: 0 auto;
      max-width: 96px; }
    body.page-template-teams #teams-wrapper .team .info,
    body.page-template-schedule-results #teams-wrapper .team .info {
      text-align: center;
      padding: 10px 0 0 0; }
      body.page-template-teams #teams-wrapper .team .info h3,
      body.page-template-schedule-results #teams-wrapper .team .info h3 {
        font-family: "Roboto Condensed", sans-serif;
        font-size: 0.875em;
        letter-spacing: 0.05em;
        text-transform: uppercase; }
        body.page-template-teams #teams-wrapper .team .info h3 a,
        body.page-template-schedule-results #teams-wrapper .team .info h3 a {
          color: #2a2a2a; }

@media screen and (min-width: 1024px) {
  body.page-template-history section#main article {
    width: 66.6666%; } }

body.page-template-history section#main article h3 {
  color: #3BAF29;
  padding: 20px 0 10px 0;
  font-size: 1.5em; }

body.page-template-history section#main table {
  width: 100%; }
  body.page-template-history section#main table thead {
    font-weight: bold; }
    body.page-template-history section#main table thead td {
      padding: 10px;
      border-bottom: 2px solid #2a2a2a;
      font-family: "Roboto Condensed", sans-serif; }
  body.page-template-history section#main table .year {
    width: 10%; }
  body.page-template-history section#main table .champion,
  body.page-template-history section#main table .runner-up,
  body.page-template-history section#main table .location {
    width: 30%; }
  body.page-template-history section#main table tbody tr:nth-child(even) {
    background: #fafafa; }
  body.page-template-history section#main table tbody td {
    border-top: 1px solid #eee;
    padding: 12px 10px;
    font-size: 0.875em;
    font-family: "Roboto Condensed", sans-serif; }
    body.page-template-history section#main table tbody td.champion {
      font-weight: bold; }

body.template-watch-live section#main article {
  width: 100%;
  max-width: 800px;
  padding: 0 0 25px 0; }

body.template-watch-live section.schedule {
  width: 100%;
  max-width: 800px;
  padding: 0 0 100px 0; }
  body.template-watch-live section.schedule:last-of-type {
    padding: 0; }
  body.template-watch-live section.schedule .day {
    padding: 0 0 100px 0; }
    body.template-watch-live section.schedule .day:last-of-type {
      padding: 0; }
  body.template-watch-live section.schedule h3 {
    font-size: 2em;
    padding: 0 0 40px 0; }
  body.template-watch-live section.schedule h4 {
    color: #3BAF29;
    font-size: 1em;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    padding: 0 0 10px 0; }
  body.template-watch-live section.schedule table {
    width: 100%; }
    body.template-watch-live section.schedule table .round {
      width: 15%; }
    body.template-watch-live section.schedule table .match-up {
      width: 25%; }
    body.template-watch-live section.schedule table .time {
      width: 15%; }
    body.template-watch-live section.schedule table .link {
      width: 15%; }
    body.template-watch-live section.schedule table thead tr {
      border-bottom: 2px solid #2a2a2a; }
      body.template-watch-live section.schedule table thead tr th {
        text-align: left;
        padding: 10px;
        font-size: 0.875em; }
    body.template-watch-live section.schedule table tbody td {
      padding: 10px;
      font-size: 0.875em; }
      body.template-watch-live section.schedule table tbody td a:hover {
        text-decoration: underline; }

section#news {
  padding: 30px 0; }
  @media screen and (min-width: 768px) {
    section#news {
      padding: 50px 0; } }
  section#news .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start; }
  section#news article {
    width: 100%;
    padding: 0 0 75px 0; }
    @media screen and (min-width: 640px) {
      section#news article {
        width: 47.5%; } }
    section#news article .info {
      background: #fff;
      border: 1px solid rgba(0, 42, 92, 0.2);
      box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
      padding: 20px;
      width: 95%;
      margin: -20px auto 0 auto; }
      @media screen and (min-width: 640px) {
        section#news article .info {
          margin: -20px auto 0 auto;
          padding: 40px;
          width: 90%; } }
      section#news article .info h4 {
        color: #3BAF29;
        font-size: 0.875em;
        padding: 0 0 5px 0; }
        @media screen and (min-width: 640px) {
          section#news article .info h4 {
            font-size: 1em; } }
      section#news article .info h3 {
        font-size: 1.5em;
        padding: 0 0 20px 0; }
        @media screen and (min-width: 640px) {
          section#news article .info h3 {
            font-size: 2em; } }
        section#news article .info h3 a {
          color: #3BAF29; }
      section#news article .info p {
        font-family: "Merriweather", serif;
        font-size: 0.8125em;
        line-height: 1.75em; }
  section#news #ajax-load-more {
    width: 100%; }
  section#news .alm-btn-wrap {
    text-align: center;
    width: 100%; }
    section#news .alm-btn-wrap button {
      border: none;
      margin: 0 auto; }
      section#news .alm-btn-wrap button:active, section#news .alm-btn-wrap button:focus {
        outline: none; }
      section#news .alm-btn-wrap button.done {
        display: none; }

@media screen and (min-width: 768px) {
  body.template-single-post #news {
    padding: 100px 0; } }

body.template-single-post #news article {
  font-family: "Merriweather", serif;
  width: 100%;
  max-width: 720px;
  padding: 0; }
  @media screen and (min-width: 1024px) {
    body.template-single-post #news article {
      margin: 0 0 0 10%; } }

body.single-team section#main article #bio img {
  float: left;
  max-width: 96px;
  margin: 10px 20px 20px 0; }

body.single-team section#main article #bio p {
  font-family: "Merriweather", serif;
  font-size: 0.9375em;
  line-height: 1.75em; }

body.single-team section#main article #bio ul, body.single-team section#main article #bio ol {
  margin: 0 0 30px 30px; }
  body.single-team section#main article #bio ul li, body.single-team section#main article #bio ol li {
    margin: 0 0 10px 0;
    font-family: "Merriweather", serif;
    font-size: 0.875em;
    line-height: 1.5em; }

body.single-team section#main article #bio ul {
  list-style-type: disc; }

body.single-team section#main article #bio ol {
  list-style-type: decimal; }

body.single-team section#main article #bio h4 {
  padding: 0 0 10px 0;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.25em; }

body.single-team section#main article #team-history {
  clear: both; }
  body.single-team section#main article #team-history h5 {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 1em; }

body.single-team section#main .aside-wrapper {
  justify-content: flex-start; }
  @media screen and (min-width: 1024px) {
    body.single-team section#main .aside-wrapper {
      padding: 0 0 0 40px; } }

body.single-team section#main aside#roster {
  width: 100%; }
  body.single-team section#main aside#roster h3 {
    padding: 0 0 10px 0;
    font-size: 1.25em;
    border-bottom: 2px solid;
    font-family: "Roboto Condensed", sans-serif; }
  body.single-team section#main aside#roster div.player {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    padding: 15px 5px;
    font-size: 0.875em; }
    body.single-team section#main aside#roster div.player + div.player {
      border-top: 1px solid #eee; }
    body.single-team section#main aside#roster div.player span.number {
      width: 10%;
      font-weight: bold; }
    body.single-team section#main aside#roster div.player span.name {
      width: 70%; }
    body.single-team section#main aside#roster div.player span.year {
      width: 25%; }
  body.single-team section#main aside#roster p {
    padding: 0 0 40px 0;
    font-size: 0.9375em; }

body.template-photo-essay #photo-essay {
  max-width: 1000px;
  margin: 0 auto; }
  body.template-photo-essay #photo-essay .photo {
    background: #1a1a1a;
    color: #fff;
    padding: 20px;
    margin: 0 0 20px 0; }
    body.template-photo-essay #photo-essay .photo .image {
      display: table;
      margin: 0 auto; }
      body.template-photo-essay #photo-essay .photo .image img {
        margin: 0 auto; }
      body.template-photo-essay #photo-essay .photo .image .credit {
        position: absolute;
        right: 0;
        bottom: 0; }
        body.template-photo-essay #photo-essay .photo .image .credit span {
          display: inline-block;
          padding: 10px 15px 8px 15px;
          font-family: "Montserrat", sans-serif;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 0.75em;
          color: #fff;
          background: rgba(188, 169, 91, 0.8); }
          @media screen and (min-width: 640px) {
            body.template-photo-essay #photo-essay .photo .image .credit span {
              font-size: 1em; } }
    body.template-photo-essay #photo-essay .photo .caption {
      padding: 20px 0 0 0; }
      @media screen and (min-width: 640px) {
        body.template-photo-essay #photo-essay .photo .caption {
          padding: 20px 20px 0 20px; } }
      body.template-photo-essay #photo-essay .photo .caption p {
        font-family: "Merriweather", serif;
        font-size: 0.75em;
        line-height: 1.5em; }
        @media screen and (min-width: 640px) {
          body.template-photo-essay #photo-essay .photo .caption p {
            font-size: 0.8125em; } }

body.template-photo-index #galleries {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start; }
  @media screen and (min-width: 640px) {
    body.template-photo-index #galleries {
      min-height: 600px; } }
  body.template-photo-index #galleries .photo-essay {
    margin: 0 0 20px 0;
    width: 100%;
    background: #1a1a1a;
    color: #fff;
    padding: 20px; }
    @media screen and (min-width: 640px) {
      body.template-photo-index #galleries .photo-essay {
        width: 47.5%; } }
    body.template-photo-index #galleries .photo-essay .image {
      display: flex;
      justify-content: center; }
    body.template-photo-index #galleries .photo-essay .info {
      padding: 20px 0 0 0;
      text-align: center; }
      body.template-photo-index #galleries .photo-essay .info h4 {
        font-size: 1.25em;
        font-family: "Montserrat", sans-serif;
        text-transform: uppercase; }
        body.template-photo-index #galleries .photo-essay .info h4 a {
          color: #fff; }
      body.template-photo-index #galleries .photo-essay .info p {
        font-family: "Merriweather", serif;
        font-size: 0.75em;
        font-style: italic;
        padding: 10px 0 0 0; }

body.template-videos #video-player,
body.single-video #video-player {
  width: 100%;
  background: #2a2a2a;
  padding: 50px 0;
  min-height: 380px; }
  @media screen and (min-width: 1024px) {
    body.template-videos #video-player,
    body.single-video #video-player {
      min-height: 700px; } }
  body.template-videos #video-player .info,
  body.single-video #video-player .info {
    color: #fff;
    padding: 25px 0 0 0; }
    body.template-videos #video-player .info h3,
    body.single-video #video-player .info h3 {
      font-size: 1.25em;
      padding: 0 0 10px 0; }
      @media screen and (min-width: 768px) {
        body.template-videos #video-player .info h3,
        body.single-video #video-player .info h3 {
          font-size: 2em; } }
    body.template-videos #video-player .info p,
    body.single-video #video-player .info p {
      font-size: 0.875em;
      line-height: 1.25em; }
      @media screen and (min-width: 768px) {
        body.template-videos #video-player .info p,
        body.single-video #video-player .info p {
          font-size: 1.25em; } }

body.template-videos #video-gallery,
body.single-video #video-gallery {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  body.template-videos #video-gallery .thumbnail,
  body.single-video #video-gallery .thumbnail {
    width: 48%;
    margin: 0 1% 30px 1%; }
    @media screen and (min-width: 640px) {
      body.template-videos #video-gallery .thumbnail,
      body.single-video #video-gallery .thumbnail {
        width: 31.3333%; } }
    @media screen and (min-width: 768px) {
      body.template-videos #video-gallery .thumbnail,
      body.single-video #video-gallery .thumbnail {
        width: 23%; } }
    body.template-videos #video-gallery .thumbnail span,
    body.single-video #video-gallery .thumbnail span {
      padding: 10px 0 0 0;
      display: block;
      font-size: 0.875em;
      font-weight: bold;
      line-height: 1.25em; }

body.template-press-info #ctas {
  margin: auto; }
  @media screen and (min-width: 1024px) {
    body.template-press-info #ctas {
      margin: 0 0 0 auto; } }
  body.template-press-info #ctas .cta {
    margin: 0 0 20px 0; }
    body.template-press-info #ctas .cta .btn {
      max-width: 200px;
      font-size: 1.125em;
      padding: 16px 24px 14px; }

body.template-password-protected-photos #main {
  min-height: 600px; }
  body.template-password-protected-photos #main article .password-form p {
    margin: 0 0 20px 0;
    font-size: 1.25em;
    line-height: 1.125em; }
    body.template-password-protected-photos #main article .password-form p label {
      font-size: 0.875em; }
      body.template-password-protected-photos #main article .password-form p label input[type="password"] {
        padding: 5px 5px 4px 5px;
        margin: 0 0 0 10px; }
        body.template-password-protected-photos #main article .password-form p label input[type="password"]:active, body.template-password-protected-photos #main article .password-form p label input[type="password"]:focus {
          outline: none; }
    body.template-password-protected-photos #main article .password-form p input[type="submit"] {
      background: #3BAF29;
      color: #fff;
      font-family: "Montserrat", sans-serif;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 0.875em;
      line-height: 1em;
      display: inline-block;
      padding: 10px 24px 8px;
      text-align: center;
      border: none;
      margin: 0 0 0 10px; }
      body.template-password-protected-photos #main article .password-form p input[type="submit"]:active, body.template-password-protected-photos #main article .password-form p input[type="submit"]:focus {
        outline: none; }
  body.template-password-protected-photos #main article .photos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 50px 0 0 0; }
    body.template-password-protected-photos #main article .photos .photo {
      width: 100%;
      margin: 0 0 30px 0; }
      @media screen and (min-width: 640px) {
        body.template-password-protected-photos #main article .photos .photo {
          width: 48%; } }
      @media screen and (min-width: 768px) {
        body.template-password-protected-photos #main article .photos .photo {
          width: 31.3333%; } }
      body.template-password-protected-photos #main article .photos .photo span {
        padding: 10px 0 0 0;
        display: block;
        text-align: center;
        font-size: 0.875em; }
